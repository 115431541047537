import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Link,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useHistory } from "react-router-dom";
import { isLogin } from "../../../utils";
import routes from "../../../../components/navigation/routes";

const StyledIconButton = styled(IconButton)`
  height: 100%;
  color: ${({ theme }) => theme.palette.colors.brand.secondary};
`;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 2,
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const PrimaryColorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.colors.text.primary,
}));

function AvatarMenu({ handleLogout }) {
  const theme = useTheme();
  const [avatarMenu, setAvatarMenu] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAvatarMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAvatarMenu(null);
  };
  const history = useHistory();

  const loggedInMenu = () => (
    <StyledMenu
      sx={{ mt: "26px" }}
      id="menu-appbar"
      anchorEl={avatarMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(avatarMenu)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem
        key={1}
        onClick={() => {
          handleCloseUserMenu();
          history.push(routes.USERPROFILE);
        }}
      >
        <Typography textAlign="center">Account</Typography>
      </MenuItem>
      <Link
        href="https://merchantinfo.yolo.my/yolomy-help/"
        target="_blank"
        style={{ textDecoration: "none", color: theme.palette.colors.text.primary }}
      >
        <MenuItem
          key={2}
          onClick={() => {
            handleCloseUserMenu();
          }}
        >
          <Typography textAlign="center">Help and Support</Typography>
        </MenuItem>
      </Link>

      <MenuItem key={3} onClick={() => handleLogout()}>
        <Typography textAlign="center">Logout</Typography>
      </MenuItem>
    </StyledMenu>
  );

  const guestMenu = () => (
    <StyledMenu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={avatarMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(avatarMenu)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem
        key={1}
        onClick={() => {
          handleCloseUserMenu();
          history.push(routes.AUTH);
        }}
      >
        <PrimaryColorText textAlign="center">Login</PrimaryColorText>
      </MenuItem>
      <MenuItem
        key={2}
        onClick={() => {
          handleCloseUserMenu();
          history.push(routes.AUTH);
        }}
      >
        <PrimaryColorText textAlign="center">Create Account</PrimaryColorText>
      </MenuItem>
      <Link href={`${process.env.REACT_APP_MERCHANT}/login`} style={{ textDecoration: "none" }}>
        <MenuItem key={3} onClick={() => handleCloseUserMenu()}>
          <PrimaryColorText textAlign="center">Login as a Merchant</PrimaryColorText>
        </MenuItem>
      </Link>
      <MenuItem key={4} onClick={() => handleCloseUserMenu()}>
        <PrimaryColorText textAlign="center">Help</PrimaryColorText>
      </MenuItem>
    </StyledMenu>
  );

  return (
    <>
      <Tooltip title="Menu">
        <StyledIconButton onClick={handleOpenUserMenu}>
          <PersonIcon />
        </StyledIconButton>
      </Tooltip>
      {isLogin() ? loggedInMenu() : guestMenu()}
    </>
  );
}

AvatarMenu.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default AvatarMenu;
